import React from "react";
import { PageLayout } from "@components/";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
// import * as styles from "./index.module.scss";
import * as styles from "../../components/CMS/CommonPostStyles.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { Typography } from "@components/";
import { getTableOfContents, processCmsContent } from "@helpers/CmsProcessing";
import TableOfContents from "@components/CMS/components/TableOfContents";
import { useTrackSidebar } from "@helpers/Hooks";
import CmsArticleBody from "@components/CMS/components/CmsArticleBody";
import Button from "@components/Button_new/Button";

export default function LIMSPage() {
	const { lims } = useStaticQuery(graphql`
		{
			lims: allNodeLims {
				edges {
					node {
						id
						title
						body {
							processed
						}
					}
				}
			}
		}
	`);
	const node = lims.edges[0].node;
	const body_elements = processCmsContent(node.body.processed);
	const tableOfContent = getTableOfContents(body_elements);
	useTrackSidebar(
		body_elements
			.filter((node: any) => node !== null && node.type === "h4")
			.map((toc: any) => toc.props.id),
		"marine",
		"text-dark"
	);
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "lims" }} defaultHeader="light">
			<BackgroundFadeContainer
				type="solidLightPink"
				withHeaderPadding
				className={styles.fadeContainer}
			>
				<div className={styles.templateContainer}>
					<div className={styles.header}>
						<Typography
							variant="HEADER1"
							style={{ marginBottom: 32, maxWidth: 740, }}
							color="text-dark"
						>
							{node.title}
						</Typography>
						<Button 
							style={{ width: 204, marginBottom: 60 }}
							to={`https://app.genemod.net/signup`}
						>
							Sign up free
						</Button>
					</div>
					<div className={styles.postContainer}>
						<div className={styles.left}>
							<CmsArticleBody theme="light" contents={body_elements} />
						</div>
						<div className={styles.right}>
							<TableOfContents contents={tableOfContent} theme="light" />
						</div>
					</div>
				</div>
			</BackgroundFadeContainer>
		</PageLayout>
	);
}
