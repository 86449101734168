import classNames from "classnames";
import React from "react";
import * as styles from "./index.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import Typography from "@components/Typography/Typography";

export type TableOfContentsProps = {
	contents: any[];
	theme?: "light" | "dark";
};

export default function TableOfContents({
	contents,
}: // theme = "dark",
TableOfContentsProps): JSX.Element {
	return (
		<div className={classNames(styles.container)}>
			<TypographyV2
				variant="HEADING_5"
				color="brand-500"
				style={{ marginBottom: 20 }}
			>
				Table of contents
			</TypographyV2>
			<div className={styles.tocLinks}>
					{contents}
			</div>
		</div>
	);
}
